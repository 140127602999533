(function($) {

	$.fn.initMenuResponsive = function() {

		this.click(function() {

			$('#nav-icon2').toggleClass('open');

			if($(".c-submenu-mobile").is(".open-submenu")){

				$(".c-submenu-mobile").removeClass("open-submenu");
				$('.wrap-content').removeClass('dark');
				$('.header-breadcrumb').removeClass('dark');

			}else {

				$(".c-submenu-mobile").addClass("open-submenu");
				$('.wrap-content').addClass('dark');
				$('.header-breadcrumb').addClass('dark');

			}

		});

		return this;

	}

})(jQuery);

$(function() {

	$("#menu-button-responsiv").initMenuResponsive();

});

