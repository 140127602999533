(function($) {

	$.fn.initYearClick = function() {

		this.click(function() {

			var kotva = $(this).attr("href").substring(11,13);

			// kotva = kotva.toNumber();
			
			$(".image-item").removeClass("on");

			$(".image-item-"+kotva).addClass("on");

			$("#text-all").scrollTo('#text-item-'+kotva, 400);

			$(".year-item").removeClass("on");

			$(this).addClass("on");

			return false;

		});

		return this;

	}

})(jQuery);

(function($) {

	$.fn.initHistoryClick = function() {

		this.click(function() {

			if ($(this).is(".open-history")) {

				$("#close-history").addClass("open-history");
				$(".history-text").addClass("open-history");
				$(this).removeClass("open-history");


			}else {

				$("#close-history").removeClass("open-history");
				$(".history-text").removeClass("open-history");
				$(this).addClass("open-history");


			}

		});

		return this;

	}

})(jQuery);


(function($) {

	$.fn.initCloseHistoryClick = function() {

		this.click(function() {

			$("#show-history").addClass("open-history");
			$("#close-history").removeClass("open-history");
			$(".history-text").removeClass("open-history");

		});

		return this;

	}

})(jQuery);


$(function() {

	$(".image-item-18").addClass("on");
	$(".year-item-18").addClass("on");

	$(".year-item").initYearClick();

	$("#show-history").initHistoryClick();

	$("#close-history").initCloseHistoryClick();

	$("#text-all").scroll(function() {

		var pozice = $(this).scrollTop();

		//alert("pozice"+Math.round(pozice));

		var posun = 18 - (Math.round(pozice/500));

		//alert("posun"+posun);

		$(".year-item").removeClass("on");
		$(".year-item-"+posun).addClass("on");

		$(".image-item").removeClass("on");
		$(".image-item-"+posun).addClass("on");

	});

});


/*
$($0).scroll(function() {
	console.log($(this).scrollTop());

});

*/
