(function($) {

	$.fn.initMenuClick = function() {

		this.click(function() {

			$('#nav-icon2a').toggleClass('open');


			if($(".c-menu ul").is(".open")){

				$(".c-menu ul").removeClass("open");
				$(".c-submenu").removeClass("no-open");

			}else {

				$(".c-menu ul").addClass("open");
				$(".c-submenu").addClass("no-open");

			}

		});

		return this;

	}

})(jQuery);

$(function() {

	$("#menu-show").initMenuClick();

});
