(function($) {

	$.fn.initNavHover = function() {

		this.hover(function() {

			var id = $(this).attr("data-id");
			$('.level-1').removeClass('open-submenu');
			$('.c-submenu').addClass('open-submenu');
			$('#show-submenu-'+id).addClass('open-submenu');
			$('.wrap-content').addClass('dark');
			$('.header-breadcrumb').addClass('dark');


		}, function() {

			$('.c-submenu').removeClass('open-submenu');
			$('.wrap-content').removeClass('dark');
			$('.header-breadcrumb').removeClass('dark');

		});

		return this;

	}

})(jQuery);

$(function() {

	$(".level-menu-1").initNavHover();

	$(".c-submenu").hover(function() {

		var id= "";
		$('.c-submenu').addClass('open-submenu');
		var id = $(this).children().find(".open-submenu").attr("data-submenu");
		$('#menu-'+id).addClass('open-submenu');
		$('#menu-'+id).addClass('open');
		$('.wrap-content').addClass('dark');
		$('.header-breadcrumb').addClass('dark');

	}, function() {

		$('.level-menu-1').removeClass('open')
		$('.c-submenu').removeClass('open-submenu');
		$('.wrap-content').removeClass('dark');
		$('.header-breadcrumb').removeClass('dark');

	});

	$(".submenu").hover(function() {


	}, function() {

		$('.c-submenu').removeClass('open-submenu');
		$('.c-hero').removeClass('dark');
		$('.c-articles').removeClass('dark');
		$('.content').removeClass('dark');
		$('.c-nonstop').removeClass('dark');

	});




});


//------------ po kliku na item a odrolov�n� nahoru

(function($) {

	$.fn.initItemScroll = function() {

		this.click(function() {

			$(window).scrollTo("#menu-top", 200,  {offset: 0});

		});

		return this;

	}

})(jQuery);

$(function() {

	$(".item-menu").initItemScroll();

});
