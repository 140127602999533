(function($) {

	$.fn.initSearchClick = function() {

		this.click(function() {

			if($(".c-search").is(".open")){

				$(".c-search").removeClass("open");
				$(".wrap-content").removeClass("dark");
				$(".header-breadcrumb").removeClass("dark");

			}else {


				$(".c-search").addClass("open");
				$(".wrap-content").addClass("dark");
				$(".header-breadcrumb").addClass("dark");

			}
			setTimeout(function () {
				$(".search-in .input").focus();
			}, 200);


		});

		return this;

	}

})(jQuery);


$(function() {

	$(".search").initSearchClick();
	$("#close-search-in").initSearchClick();

});
