$(function () {

/*
	$(".c-manufacturing-program").waypoint(
		function () {
			var _this = this;
			window.setTimeout(function() {
				$(_this.element).addClass("animace");
			}, 100);

		},
		{
			offset: '68%'
		}
	)
*/

	$(".c-manufacturing-program").animateInViewport({


	});

});
