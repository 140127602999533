$(function(){

	var lastScroll;

	$(window).scroll(function() {

		var scroll = $(window).scrollTop();

		if (scroll > lastScroll) {

			//jede dolu

			$(".c-header").removeClass("fix-all");
			$(".wrap-content").removeClass("pt-all");

			if(scroll >= 60){

				$(".c-header").addClass("fix");
				$(".wrap-content").addClass("pt");

			}

		} else {

			//jede nahoro

				$(".c-header").addClass("fix-all");
				$(".wrap-content").addClass("pt-all");

				$(".c-header").removeClass("fix");
				$(".wrap-content").removeClass("pt");


		}



		lastScroll = scroll;

	});

});
