$(function() {

	$(".article-b .article").slidehide({
		"x":1, "y":0, "alpha":true, "auto":10000,
		"slides":".item",
		"contHeight":true,
		"grouping": function(){
			var sirka = $(window).width();
			if (sirka < 780) {
				return 2;

			}else {
				return 4;
			}
		},
		"buttons":"#slider2",
		"touch":true
	});

});
