$(function () {

/*
	$(".c-nonstop").waypoint(
		function () {
			var _this = this;
			window.setTimeout(function() {
				$(_this.element).addClass("animace");
			}, 500);

		},
		{
			offset: '90%'
		}
	)
*/

	$(".c-nonstop").animateInViewport({

		waypointTarget: '.text'

	});


});
